import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `width ${duration}ms ease-in-out`,
  opacity: 1,
  width: "100%"
}

const transitionStyles = {
  entering: { width: "100%", },
  entered:  { width: "100%" },
  exiting:  { width: "0", },
  exited:  { width: "0" },
};

const SlideIn = (props) =>  {    
    return (
        <Transition in={props.in} timeout={duration}>
          {state => {return (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}>
              {props.children}
            </div>
          )}}
        </Transition>
      );
}
    

export default SlideIn;
