import { useState } from 'react';
import RoomView from "./RoomView";
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
import ColorButton from './ColorButton';
import TextField from '@mui/material/TextField';

function RoomPage() {
    const { token } = useParams();
    const [name, setName] = useState("")
    const [joined, setJoined] = useState(localStorage.getItem("name") !== null)

    const handleJoin = () => {
        setJoined(true)
        localStorage.setItem("name", name)
    }

    return (
        <>
          <Helmet>
              <title>{token} - Multimelon</title>
              <meta charSet="utf-8" />
              <meta name="description" content="An online watch party!" />
          </Helmet>
          {
                joined ?
                <RoomView name={localStorage.getItem("name")} roomName={token.toLowerCase()}/>
            :
            <>
                <div className="homepage-container">
                <p className="home-desc">Before joining, set your name that will appear to others in the room</p>
                    <div className='create-container'>
                        <div className="create-text">
                            <TextField
                                className="new-room-field" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                onKeyUp={e => {
                                    if (e.key === 'Enter') {
                                        handleJoin()
                                    }
                                }} 
                            />
                            <ColorButton className="home-button" onClick={() => handleJoin()}>Set Name</ColorButton>
                        </div>
                    </div>
                </div>
            </>
          }
        </>
    )
  }
  
  export default RoomPage;
  