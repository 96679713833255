import {
  useNavigate
} from "react-router-dom";


import watermelon from './images/watermelon.svg';

function BannerComponent() {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(`/`);
  }
  return (
    <div className="banner">
        <div onClick={navigateToHome} className="logo-container">
        <img alt="" className="logo" src={watermelon}/>
        </div>
    </div>
  )
}

export default BannerComponent;
