import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import randomWords from "random-words";
import ColorButton from './ColorButton';
import TextField from '@mui/material/TextField';
import Helmet from 'react-helmet';
import Divider from '@mui/material/Divider';

function HomePage() {
    const [roomName, setRoomName] = useState("")
    const navigate = useNavigate();

    const joinRoom = () => {
        if (!roomName) {
            return;
        }

        navigate(`/room/${roomName}`);
    }

    const joinRandom = () => {
        navigate(`/room/${randomWords({exactly:1, wordsPerString:2, separator:'-', maxLength: 10})}`);
    }

    return (
        <div className="homepage-container">
            <Helmet>
              <title>Multimelon</title>
              <meta charSet="utf-8" />
              <meta name="description" content="An online watch party!" />
            </Helmet>
            <div className="home-desc-container">
                <h1 className="big-title">Multimelon</h1>
                <p className="home-desc">Watch videos with friends! Join an existing room or create a new one to sync videos across devices and have a watch party</p>
            </div>
            <div>
                <div className='create-container'>
                    <div className="create-text">
                        <TextField 
                            className="new-room-field" 
                            value={roomName} 
                            onChange={(e) => setRoomName(e.target.value)}
                            onKeyUp={e => {
                                if (e.key === 'Enter') {
                                    joinRoom()
                                }
                            }} 
                            />
                        <ColorButton className="home-button" onClick={joinRoom}>Join Existing Room</ColorButton>
                    </div>
                </div>
                <Divider />
                <div className='join-container'>
                    <div className="create-text">
                        <ColorButton className="home-button" onClick={joinRandom}>Create New Room</ColorButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage;
