
import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import uniqid from 'uniqid';
import * as Y from 'yjs'
import { WebrtcProvider } from 'y-webrtc'

import TextField from '@mui/material/TextField';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Slider from '@mui/material/Slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import debounce from 'lodash.debounce';

import SlideIn from './SlideIn';
import Duration from './Duration';
import ColorButton from './ColorButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';

import bear from './avatars/icons8-bear-64.png';
import bee from './avatars/icons8-bee-64.png';
import bird from './avatars/icons8-bird-64.png';
import blackJaguar from './avatars/icons8-black-jaguar-64.png';
import bull from './avatars/icons8-bull-64.png';
import butterfly from './avatars/icons8-butterfly-64.png';
import cat from './avatars/icons8-cat-64.png';
import chicken from './avatars/icons8-chicken-64.png';
import cobra from './avatars/icons8-cobra-64.png';
import cow from './avatars/icons8-cow-64.png';
import deer from './avatars/icons8-deer-64.png';
import dog from './avatars/icons8-dog-64.png';
import elephant from './avatars/icons8-elephant-64.png';
import falcon from './avatars/icons8-falcon-64.png';
import femaleLion from './avatars/icons8-female-lion-64.png';
import fish from './avatars/icons8-fish-64.png';
import fox from './avatars/icons8-fox-64.png';
import frog from './avatars/icons8-frog-64.png';
import giraffe from './avatars/icons8-giraffe-64.png';
import gorilla from './avatars/icons8-gorilla-64.png';
import hamster from './avatars/icons8-hamster-64.png';
import hedgehog from './avatars/icons8-hedgehog-64.png';
import hippopotamus from './avatars/icons8-hippopotamus-64.png';
import horse from './avatars/icons8-horse-64.png';
import insect from './avatars/icons8-insect-64.png';
import jellyfish from './avatars/icons8-jellyfish-64.png';
import lamb from './avatars/icons8-lamb-64.png';
import lion from './avatars/icons8-lion-64.png';
import lizard from './avatars/icons8-lizard-64.png';
import llama from './avatars/icons8-llama-64.png';
import mouseAnimal from './avatars/icons8-mouse-animal-64.png';
import octopus from './avatars/icons8-octopus-64.png';
import orangutan from './avatars/icons8-orangutan-64.png';
import panda from './avatars/icons8-panda-64.png';
import penguin from './avatars/icons8-penguin-64.png';
import pig from './avatars/icons8-pig-64.png';
import polarBear from './avatars/icons8-polar-bear-64.png';
import rabbit from './avatars/icons8-rabbit-64.png';
import rhinoceros from './avatars/icons8-rhinoceros-64.png';
import salamander from './avatars/icons8-salamander-64.png';
import shark from './avatars/icons8-shark-64.png';
import snail from './avatars/icons8-snail-64.png';
import snake from './avatars/icons8-snake-64.png';
import spider from './avatars/icons8-spider-64.png';
import squirrel from './avatars/icons8-squirrel-64.png';
import starfish from './avatars/icons8-starfish-64.png';
import stork from './avatars/icons8-stork-64.png';
import turkeycock from './avatars/icons8-turkeycock-64.png';
import turtle from './avatars/icons8-turtle-64.png';
import walrus from './avatars/icons8-walrus-64.png';
import whale from './avatars/icons8-whale-64.png';
import whiteJaguar from './avatars/icons8-white-jaguar-64.png';
import wolf from './avatars/icons8-wolf-64.png';
import zebra from './avatars/icons8-zebra-64.png';


let ydoc;

const animalMap = {
    'bear': bear,
    'bee': bee,
    'bird': bird,
    'black-jaguar': blackJaguar,
    'bull': bull,
    'butterfly': butterfly,
    'cat': cat,
    'chicken': chicken,
    'cobra': cobra,
    'cow': cow,
    'deer': deer,
    'dog': dog,
    'elephant': elephant,
    'falcon': falcon,
    'female-lion': femaleLion,
    'fish': fish,
    'fox': fox,
    'frog': frog,
    'giraffe': giraffe,
    'gorilla': gorilla,
    'hamster': hamster,
    'hedgehog': hedgehog,
    'hippopotamus': hippopotamus,
    'horse': horse,
    'insect': insect,
    'jellyfish': jellyfish,
    'lamb': lamb,
    'lion': lion,
    'lizard': lizard,
    'llama': llama,
    'mouse-animal': mouseAnimal,
    'octopus': octopus,
    'orangutan': orangutan,
    'panda': panda,
    'penguin': penguin,
    'pig': pig,
    'polar-bear': polarBear,
    'rabbit': rabbit,
    'rhinoceros': rhinoceros,
    'salamander': salamander,
    'shark': shark,
    'snail': snail,
    'snake': snake,
    'spider': spider,
    'squirrel': squirrel,
    'starfish': starfish,
    'stork': stork,
    'turkeycock': turkeycock,
    'turtle': turtle,
    'walrus': walrus,
    'whale': whale,
    'white-jaguar': whiteJaguar,
    'wolf': wolf,
    'zebra': zebra,
}



const RoomView = (props) => {
    const [id, setId] = useState(null)
    const [url, setUrl] = useState(null)
    const [pip, setPip] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [controls, setControls] = useState(false)
    const [light, setLight] = useState(false)
    const [volume, setVolume] = useState(1.0)
    const [muted, setMuted] = useState(false)
    const [played, setPlayed] = useState(0)
    const [loaded, setLoaded] = useState(0)
    const [duration, setDuration] = useState(0)
    const [playbackRate, setPlaybackRate] = useState(1.0)
    const [loop, setLoop] = useState(false)
    const [seeking, setSeeking] = useState(false)
    const [urlInput, setUrlInput] = useState("")
    const [joiners, setJoiners] = useState([])
    const [volumeSliderVisible, setVolumeSliderVisible] = useState(false)

    const [name, setName] = useState(props.name)
    const [editName, setEditName] = useState(false)
    const [updatedName, setUpdatedName] = useState("")

    const updateName = () => {
        localStorage.setItem("name", updatedName)
        setName(updatedName)
        setEditName(!editName)
    }

    const toggleEditName = () => {
        setEditName(!editName)
    }
  
    useEffect(() => {
      
      ydoc = new Y.Doc(); 
  
      let provider = null;
      try {
        provider = new WebrtcProvider(props.roomName, ydoc, {
          signaling: ['wss://multimelon.com/signaling'],
        });
  
        const awareness = provider.awareness
        const ySettings = ydoc.getMap('settings')
        const ySeekPlace = ydoc.getMap('seekPlace')
  
        provider.on('synced', synced => {
            console.log('room new joiner: ', ySettings.toJSON())
        })
        
  
        ySettings.observeDeep(() => {
  
          if (ySettings.get('url') !== undefined) {
            setUrl(ySettings.get('url'))
          }
  
          if (ySettings.get('played') !== undefined) {
            setPlayed(ySettings.get('played'))
          }
  
          if (ySettings.get('loaded') !== undefined) {
            setLoaded(ySettings.get('loaded'))
          }
  
          if (ySettings.get('pip') !== undefined) {
            setPip(ySettings.get('pip'))
          }
  
          if (ySettings.get('playing') !== undefined) {
            setPlaying(ySettings.get('playing'))
          }
  
          if (ySettings.get('controls') !== undefined) {
            setControls(ySettings.get('controls'))
          }
  
          if (ySettings.get('loop') !== undefined) {
            setLoop(ySettings.get('loop'))
          }
  
          if (ySettings.get('muted') !== undefined) {
            setMuted(ySettings.get('muted'))
          }
  
          if (ySettings.get('playbackRate') !== undefined) {
            setPlaybackRate(ySettings.get('playbackRate'))
          }
  
          if (ySettings.get('seeking') !== undefined) {
            setSeeking(ySettings.get('seeking'))
          }
  
          if (ySettings.get('duration') !== undefined) {
            setDuration(ySettings.get('duration'))
          }
        })
  
        ySeekPlace.observeDeep(() => {
            if (ySeekPlace.get('pos') !== undefined && Math.abs(played - ySeekPlace.get('pos')) * 100 > 3.0) {
                player.current.seekTo(ySeekPlace.get('pos'))
              }
        })

        const userColors = [
            '#30bced',
            '#6eeb83',
            '#ffbc42',
            '#ecd444',
            '#ee6352',
            '#9ac2c9',
            '#8acb88',
            '#1be7ff'
        ]

        const userAnimals = [
            'bear',
            'bee',
            'bird',
            'black-jaguar',
            'bull',
            'butterfly',
            'cat',
            'chicken',
            'cobra',
            'cow',
            'deer',
            'dog',
            'elephant',
            'falcon',
            'female-lion',
            'fish',
            'fox',
            'frog',
            'giraffe',
            'gorilla',
            'hamster',
            'hedgehog',
            'hippopotamus',
            'horse',
            'insect',
            'jellyfish',
            'lamb',
            'lion',
            'lizard',
            'llama',
            'mouse-animal',
            'octopus',
            'orangutan',
            'panda',
            'penguin',
            'pig',
            'polar-bear',
            'rabbit',
            'rhinoceros',
            'salamander',
            'shark',
            'snail',
            'snake',
            'spider',
            'squirrel',
            'starfish',
            'stork',
            'turkeycock',
            'turtle',
            'walrus',
            'whale',
            'white-jaguar',
            'wolf',
            'zebra'
        ]

        const myId = uniqid()
        const myColor = userColors[Math.floor(Math.random() * userColors.length)]
        const myAnimal = userAnimals[Math.floor(Math.random() * userAnimals.length)]
          
        awareness.setLocalStateField('user', { id: myId, name: name, color: myColor, animal: myAnimal })
        setJoiners([
            {
                id: myId,
                name: name,
                color: myColor,
                animal: myAnimal
            }
        ])

        setId(myId)

        awareness.on('change', () => {
            const strings = []
            awareness.getStates().forEach(state => {
              if (state.user) {
                strings.push(state.user)
              }
              setJoiners(strings)
            })
          })
        
      } catch (err) {
        alert("error in collaborating try refreshing or come back later !");
      }
      return () => {
        
        if (provider) {
          provider.disconnect();
          ydoc.destroy();
        }
      };
    
    }, [name]);
    
  
    const player = useRef(null);

    const handleLoadVideo = () => {
      setUrl(urlInput)
      setPlayed(0)
      setLoaded(0)
      setPip(false)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('url', urlInput)
      ySettings.set('played', 0)
      ySettings.set('loaded', 0)
      ySettings.set('pip', false)
    }
  
    const handlePlayPause = () => {
      setPlaying(!playing)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('playing', !playing)
    }
  
    const handleStop = () => {
      setUrl(null)
      setPlaying(false)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('playing', false)
      ySettings.set('url', null)
    }
  
    const handleToggleLight = () => {
      setLight(!light)
    }
  
    const handleToggleLoop = () => {
      setLoop(!loop)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('loop', !loop)
    }
  
    const handleVolumeChange = e => {
      const volumePercentage = parseFloat(e.target.value) / 100
      setVolume(volumePercentage)
    }
  
    const handleToggleMuted = () => {
      setMuted(!muted)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('muted', !muted)
    }
  
    const handleSetPlaybackRate = e => {
      setPlaybackRate(parseFloat(e.target.value))
      const ySettings = ydoc.getMap('settings')
      ySettings.set('playbackRate', parseFloat(e.target.value))
    }
  
    const handleOnPlaybackRateChange = (speed) => {
      setPlaybackRate(parseFloat(speed))
      const ySettings = ydoc.getMap('settings')
      ySettings.set('playbackRate', parseFloat(speed))
    }
  
    const handleTogglePIP = () => {
      setPip(!pip)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('pip', !pip)
    }
  
    const handlePlay = () => {
      setPlaying(true)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('playing', true)
      ySettings.set('played', played)
      //const ySeekPlace = ydoc.getMap('seekPlace')
      //ySeekPlace.set('pos', played)
    }
  
    const handleEnablePIP = () => {
      setPip(true)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('pip', true)
    }
  
    const handleDisablePIP = () => {
      setPip(false)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('pip', false)
    }
  
    const handlePause = () => {
      setPlaying(false)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('playing', false)
    }
  
    const handleSeekMouseDown = e => {
      setSeeking(true)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('seeking', true)
    }

  
    const handleSeekMouseUp = e => {
      setSeeking(false)

      const playedPercentage = parseFloat(e.target.value) / 100

      player.current.seekTo(playedPercentage)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('seeking', false)
      const ySeekPlace = ydoc.getMap('seekPlace')
      ySeekPlace.set('pos', playedPercentage)
      setPlayed(playedPercentage)
    }
  
    const handleProgress = state => {
      if (!seeking) {
        if (state.url) {
          setUrl(state.url)
        }
        
        if (state.pip) {
          setPip(state.pip)
        }
  
        if (state.playing) {
          setPlaying(state.playing)
        }
  
        if (state.controls) {
          setControls(state.controls)
        }
  
        if (state.light) {
          setLight(state.light)
        }
  
        if (state.volume) {
          //setVolume(state.volume)
        }
  
        if (state.muted) {
          setMuted(state.muted)
        }
  
        if (state.played) {
          setPlayed(state.played)
        }
  
        if (state.loaded) {
          setLoaded(state.loaded)
        }
  
        if (state.duration) {
          setDuration(state.duration)
        }
  
        if (state.playbackRate) {
          setPlaybackRate(state.playbackRate)
        }
  
        if (state.loop) {
          setLoop(state.loop)
        }
  
        if (state.seeking) {
          setSeeking(state.seeking)
        }
  
        if (state.urlInput) {
          setUrlInput(state.urlInput)
        }
      }
    }
  
    const handleEnded = () => {
      setPlaying(loop)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('playing', loop)
    }
  
    const handleDuration = (duration) => {
      setDuration(duration)
      const ySettings = ydoc.getMap('settings')
      ySettings.set('duration', duration)
    }
  
    const handleClickFullscreen = () => {
      screenfull.request(findDOMNode(player.current))
    }

    const [toolTipOpen, setToolTipOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setToolTipOpen(false);
    };

    const handleTooltipOpen = () => {
        navigator.clipboard.writeText(`https://www.multimelon.com/room/${props.roomName}`);
        setToolTipOpen(true);
        debounce(() => {
            setToolTipOpen(false);
          }, 1000)();
    };
  
    return (
      <div className="App">
            <div className="content-container">
                <div className="video-container">
                    <ReactPlayer
                        ref={player}
                        className='react-player'
                        width='100%'
                        height='100%'
                        url={url}
                        pip={pip}
                        playing={playing}
                        controls={controls}
                        light={light}
                        loop={loop}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        onPlay={handlePlay}
                        onEnablePIP={handleEnablePIP}
                        onDisablePIP={handleDisablePIP}
                        onPause={handlePause}
                        onPlaybackRateChange={handleOnPlaybackRateChange}
                        //onSeek={handleSeekChange}
                        onEnded={handleEnded}
                        onError={e => console.log('onError', e)}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                    />
                    <div className="slider-container">
                        <Slider
                            className="video-slider"
                            value={played * 100}
                            //onMouseDown={handleSeekMouseDown}
                            onChange={handleSeekMouseUp}
                            //onMouseUp={handleSeekMouseUp}
                        />

                    <div className="lower-controls-container">
                        <div className="volume-slider" onMouseEnter={() => setVolumeSliderVisible(true)} onMouseLeave={() => setVolumeSliderVisible(false)}>
                            {
                            volume !== 0 && !muted ?
                            <><VolumeUpIcon onClick={() => setMuted(true)} className='volume-icon'></VolumeUpIcon></>
                            :
                            <><VolumeOffIcon onClick={() => setMuted(!muted)} className="mute-icon"></VolumeOffIcon></>
                            
                            }

                            <SlideIn in={volumeSliderVisible}>
                                <div className="volume-container">
                                    <Slider disabled={!volumeSliderVisible} onChange={handleVolumeChange} value={volume * 100}>
                            
                                    </Slider>
                                </div>
                            </SlideIn>
                            </div>
                        <div className="duration-controls">

                            <div className="duration-control">
                                <Duration className="time-passed" seconds={duration * (played)} />
                            </div>
                            <div className="duration-control">
                                {
                                    playing ?
                                        <PauseCircleIcon onClick={handlePause} fontSize="large" className="pause-button"></PauseCircleIcon>
                                    :
                                        <PlayCircleIcon onClick={handlePlay} fontSize="large" className="play-button"></PlayCircleIcon>
                                }
                            </div>
                            <div className="duration-control">
                                <Duration className="time-remaining" seconds={duration} />
                            </div>
                        </div>
                        <div className="fullscreen-control">
                                <FullscreenIcon className="fullscreen-icon" onClick={handleClickFullscreen} />
                            </div>
                    </div>

                        
                    </div>
                </div>
                
                <div className="sidebar">
                    <div className="url-form">
                        <TextField onChange={e => setUrlInput(e.target.value)} 
                            onKeyUp={e => {
                                if (e.key === 'Enter') {
                                    handleLoadVideo()
                                }
                            }} 
                            type='text' 
                            placeholder='Load video' 
                        />
                        <ColorButton onClick={handleLoadVideo}>Load</ColorButton>
                    </div>
                    <div>
                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {
                                joiners ?
                                joiners.map(user => 
                                    id !== null && user.id === id ?
                                    <>
                                        <ListItem>
                                          <ListItemAvatar>
                                            <Avatar alt={user.name} src={animalMap[user.animal]} />
                                          </ListItemAvatar>
                                          {
                                              editName ?
                                              <>
                                                <ClickAwayListener onClickAway={toggleEditName}>
                                                    <>
                                                        <TextField 
                                                            onChange={e => setUpdatedName(e.target.value)} 
                                                            onKeyUp={e => {
                                                                if (e.key === 'Enter') {
                                                                    updateName()
                                                                }
                                                            }} 
                                                            type='text' 
                                                            placeholder='Edit name' 
                                                        />
                                                        <CheckBoxIcon onClick={updateName}></CheckBoxIcon>
                                                        <DeleteIcon onClick={toggleEditName}></DeleteIcon>
                                                    </>
                                                </ClickAwayListener>
                                              </>
                                              :
                                              <ListItemText
                                                className="name-text name-self"
                                                primary={user.name}
                                                onClick={toggleEditName}
                                                />
                                          }
                                          
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </>
                                    :
                                    (
                                        <>
                                        <ListItem>
                                          <ListItemAvatar>
                                            <Avatar alt={user.name} src={animalMap[user.animal]} />
                                          </ListItemAvatar>
                                          <ListItemText
                                            className="name-text"
                                            primary={user.name}
                                          />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        </>
                                      
                                    )
                                )
                                :
                                <></>
                            }
                            </List>
                    </div>
                    <div className="copy-button-container">
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div>
                                <Tooltip
                                    onClose={handleTooltipClose}
                                    open={toolTipOpen}
                                    disableHoverListener
                                    title="Copied!"
                                >
                                    <ColorButton onClick={handleTooltipOpen}>Copy Invite Link</ColorButton>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                        
                    </div>
                    <div className="chunk-form">

                    </div>
                </div>
            </div>
            <div className="low-banner">
            </div>
      </div>
    );
}

export default RoomView;
