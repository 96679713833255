import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#b16484",
    '&:hover': {
        backgroundColor: "#8F214E",
    },
    textTransform: "unset"
}));

export default ColorButton