import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import HomePage from './HomePage';
import RoomPage from './RoomPage';
import { useWindowDimensions } from './hooks';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import watermelon from './images/watermelon.svg';
import BannerComponent from './BannerComponent';

const theme = createTheme({
  typography: {
    "fontFamily": `"Outfit"`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});

function App() {
  const { height, width } = useWindowDimensions();

  console.log(width)

  return (
    <>
    <ThemeProvider theme={theme}>
      <Router>
        <BannerComponent></BannerComponent>
        
        <Routes>
          <Route path="/" element={
            <HomePage/>
          }>
          </Route>
          <Route path="/room/:token" element={
            <RoomPage/>
          }>
          </Route>
          <Route path="*" element={
            <HomePage/>
          } />
        </Routes>
      </Router>
      <div className="footer">
        <img alt="" className="watermelon-tree-right" src={watermelon}/>
        <img alt="" className="watermelon-tree-left" src={watermelon}/>
        <img alt="" className="watermelon-tree-right" src={watermelon}/>
        <img alt="" className="watermelon-tree-left" src={watermelon}/>
        <img alt="" className="watermelon-tree-right" src={watermelon}/>
        {
          width > 840 ?
          <>
          <img alt="" className="watermelon-tree-left" src={watermelon}/>
          <img alt="" className="watermelon-tree-right" src={watermelon}/>
          <img alt="" className="watermelon-tree-left" src={watermelon}/>
          <img alt="" className="watermelon-tree-right" src={watermelon}/>
          <img alt="" className="watermelon-tree-left" src={watermelon}/>
          <img alt="" className="watermelon-tree-right" src={watermelon}/>
          <img alt="" className="watermelon-tree-left" src={watermelon}/>
          </>
          :
          <></>
        }
      </div>
      </ThemeProvider>
    </>
    
  )
}

export default App;
